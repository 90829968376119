import { Observable } from 'rxjs';
import {
    distinctUntilChanged,
    filter,
    map,
    withLatestFrom
} from 'rxjs/operators';

import Config from '../../../State/Stores/Config/Models/Config';
import Service from '../../../State/Stores/Config/Models/Service';

const crateServiceAndTokenStream = (
    configUpdate$: Observable<Config>,
    tokenUpdate$: Observable<string>
): Observable<[Service, string]> =>
    configUpdate$.pipe(
        map((config) => config.currentService),
        filter((currentService) =>
            Boolean(
                currentService.service &&
                    currentService.specification &&
                    currentService.version &&
                    currentService.environment
            )
        ),
        distinctUntilChanged(),
        withLatestFrom(tokenUpdate$)
    );

export default crateServiceAndTokenStream;
